import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    background-color: var(--main-purple);
    border: 1px solid var(--main-white);
    height: 2rem;
    width: auto;
    font-family: "Book Antiqua";
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--main-white);
    @media screen and (min-width: 560px){
        &:hover {
            background-color: var(--main-white);
            border: 1px solid var(--main-purple);
            color: var(--main-purple);
        }
        max-width: 30vw;
    }
`

export const Button = ({ children }) => {
    return (
        <StyledButton disabled={false}>
            { children}
        </StyledButton>
    )
}