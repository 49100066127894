import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const StyledBody = styled.body`
    & section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }
    & article {
        width: 95%;
        max-width: 400px;
    .hidden {
        display: none;
    }
    & form {
        display: flex;
        flex-direction: column;
        align-items: center;
        & input {
            min-height: 1.5rem;
            width: 90%;
            margin-bottom: 1rem;
        }
        & label {
            align-self: flex-start;
            margin-left: 1rem;
        }
        & textarea {
            min-height: 6rem;
            font-family: "Arial";
            width: 90%;
        }
        & #submit {
            margin-top: 2rem;
            width: 60%;
            height: 3rem;
            background-color: var(--main-purple);
            border: 1px solid var(--main-white);
            color: var(--main-white);
            font-family: "Book Antiqua";
            font-size: 1rem;
            text-transform: uppercase;
                &:hover {
                    background-color: var(--main-white);
                    border: 1px solid var(--main-purple);
                    color: var(--main-purple);
                }
                &:disabled {
                        opacity: 0.8;
                                background-color: var(--main-purple);
                                border: 1px solid var(--main-white);
                                color: var(--main-white);
                        }
                 }
          }
    }
`

export const Contact = (props) => {

    const [nameInput, setNameInput] = useState("")
    const [emailInput, setEmailInput] = useState("")
    const [messageInput, setMessageInput] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_zengp7g', 'template_skze5tp', e.target, 'user_VDPV8MX7kymuZ40PElt52')
            .then((result) => {
                console.log(result.text);
                setMessageSent(true)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <StyledBody>
            <section>
                <article>
                    {props.language === "English" ?
                        <>
                            <h1>Contact me</h1>
                            <p>Use the form below to send a message or email me directly at <a href="mailto:thomas@xpadvisory.se">thomas@xpadvisory.se</a> and I will get back to you as soon as possible.</p>
                            <p>You can also reach me at +46 704963000 or via <a href="https://www.linkedin.com/in/thomas-lindgren-71021612/">LinkedIn</a>.</p>
                        </>
                        :
                        <>
                            <h1>Kontakta mig</h1>
                            <p>Skicka ett meddelande genom formuläret eller direkt till <a href="mailto:thomas@xpadvisory.se">thomas@xpadvisory.se</a> så återkommer jag så snart som  möjligt.</p>
                            <p>Du kan också nå mig på telefonnummer 070 496 30 00 eller via <a href="https://www.linkedin.com/in/thomas-lindgren-71021612/">LinkedIn</a>.</p>
                        </>
                    }
                    <form className="contact-form" onSubmit={sendEmail}>
                        <input type="hidden" name="contact_number" />
                        <label>{props.language === "English" ? "Name" : "Namn"}</label>
                        <input
                            onChange={e => setNameInput(e.target.value)}
                            className="name" type="text" name="user_name" />
                        <label>{props.language === "English" ? "Email" : "E-post"}</label>
                        <input
                            onChange={e => setEmailInput(e.target.value)}
                            className="email" type="email" name="user_email" />
                        <label>{props.language === "English" ? "Message" : "Meddelande"}</label>
                        <textarea
                            onChange={e => setMessageInput(e.target.value)}
                            className="message" name="message" />
                        <input id="submit" type="submit" disabled={(nameInput.length < 1 || emailInput.length < 1 || messageInput.length < 1)} value={props.language === "English" ? "Send" : "Skicka"} />
                    </form>
                    {messageSent ?
                        <p>{props.language === "English" ? "Your message was sent successfully." : "Ditt meddelande har skickats."}</p>
                        : null
                    }
                </article>
            </section>
        </StyledBody>
    )
}