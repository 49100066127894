import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from './components/Header/Header.jsx'
import { Main } from './components/Main/Main'
import { Contact } from './components/Contact/Contact'
import { Footer } from './components/Footer/Footer'
import { useState } from 'react';

function App() {

    const [language, setLanguage] = useState("Swedish");

    const changeLanguage = () => {
        language === "Swedish" ?
            setLanguage("English") :
            setLanguage("Swedish");
    }


    return (
        <Router>
                <div className="App">
                    <Header language={language} changeLanguage={changeLanguage} />
                    <Switch>
                        <Route path="/contact">
                            <Contact language={language} />
                        </Route>
                        <Route path="/">
                            <Main language={language} />
                        </Route>
                    </Switch>
                    <Footer></Footer>
                </div>
        </Router>
    );
}

export default App;
