import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button'
import { LanguageButton } from '../LanguageButton/LanguageButton';

const StyledHeader = styled.header`
    background-color: var(--main-purple);
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    & a {
        display: flex;
        justify-content: center;
        text-decoration: none;
        /* width: 100%; */
    }
    & Button {
        width: 100%;
        height: 2.5rem;
        margin: 2.5px 0;
        border-radius: 15px;
        @media screen and (max-width: 559px){
            border-radius: 0;
            max-width: 275px;
            border-width: 1px 0 0 0;
        }
    }
    & article {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    & .logo {
        height: 5rem;
        @media screen and (min-width: 560px){
            margin-bottom: 2rem;
        }
        &:hover {
            cursor: pointer;
        }
    }
    & nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media screen and (min-width: 560px) {
            flex-direction: row;
            justify-content: space-between;
            max-width: 95%;
            & a {
                margin-bottom: -28.5px;
                z-index: 5;
                & Button {
                    height: 3.8rem;
                    width: 15rem;
                    z-index: 5;
                }
            }
        }
        @media screen and (min-width: 1024px) {
            max-width: 80%;
        }
        @media screen and (min-width: 1440px) {
            max-width: 60%;
        }
    }
`

export const Header = (props) => {
    return (
        <StyledHeader>
            <article>
                <LanguageButton language={props.language} changeLanguage={props.changeLanguage}/>
            </article>
            <Link id="logo" to="/">
                <img src="./xp-advisory-logo.svg" alt="" className="logo" />
            </Link>
            <nav>
                <HashLink
                    to="/#affarsradgivning"
                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })}
                >
                    <Button> {props.language === "Swedish" ? 
                        "Affärsrådgivning"
                        :
                        "Business Consulting"
                    }
                    </Button>
                </HashLink>
                <HashLink
                    to="/#bolagsstyrning"
                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })}
                >
                    <Button> {props.language === "Swedish" ? 
                        "Bolagsstyrning"
                        :
                        "Corporate Governance"
                    }  
                    </Button>
                </HashLink>
                <HashLink
                    to="/#forelasningar"
                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })}
                >
                    <Button>
                    {props.language === "Swedish" ? 
                        "Föreläsningar"
                        :
                        "Lectures"
                    }  
                    </Button>
                </HashLink>
            </nav>
        </StyledHeader>
    )
}