import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
    width: 2.5rem;
    cursor: pointer;
    padding: 1rem;
    border-radius: 1.2rem;
    /* max-height: 1.5rem; */
    max-height: 2rem;
    @media screen and (min-width: 680px){
    }
    @media screen and (min-width: 1024px){
        width: 3rem;
    }
`

export const LanguageButton = (props) => {
    return (
        <StyledImg
            className="flag"
            onClick={props.changeLanguage}
            src={props.language === "English" ? "./swedish-flag.jpg" : "./english-flag.svg"} 
            alt="language">
        </StyledImg>
        // <StyledButton onClick={props.changeLanguage}>{props.language === "Swedish" ? "English" : "Svenska"}</StyledButton>
    )
} 
