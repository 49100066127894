import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
    background-color: var(--main-purple);
    height: 10em;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
        color: var(--main-white);
        margin: 0.1rem;
    }
`

export const Footer = (props) => {
    return (
        <StyledFooter>
            <p>Thomas Lindgren</p>
            <p>XP Advisory AB</p>
            <p>thomas@xpadvisory.se</p>
            <p>Tel: 070 496 30 00</p>
        </StyledFooter>
    )
}