import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';

const StyledBody = styled.body`
display: flex;
flex-direction: column;
align-items: center;
& Button {
    height: 3rem;
    width: 15rem;
}
& section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    & .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 1rem;
        & img {
            max-width: 100vw;
            @media screen and (max-width: 559px){
            max-width: 250px;
        }
        }
    }
        @media screen and (min-width: 560px){
            & .investments-intro, .investment-wrapper {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            & .content {
                flex-direction: row-reverse;
                align-items: flex-start;
                justify-content: space-between;
                & img {
                    max-width: 35%;
                }
            }
           & .text-content {
               width: 50%;
           }
       }
       @media screen and (min-width: 1024px){
           & .investments {
               display: flex;
           }
           & img {
               max-width: 30%;
           }
       
        
    }
}
& section:nth-of-type(2n){
    background-color: var(--main-purple);
    & p, h1, li {
        color: var(--main-white);
    }
}

    @media screen and (min-width: 1024px) {
        & h1, .content, .investments-intro, .investment-wrapper {
            width: 80%;
            p, li, a {

            }
        }
        & .investment-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            & .investments {
                margin: 1rem 0;
                width: 100%;
                & article {
                    width: 50%;
                }
            }
        }    
        }
        @media screen and (min-width: 1440px) {
            & h1, .content, .investments-intro, .investment-wrapper {
            max-width: 60%;
        }    
        }

`

export const Main = (props) => {
    return (
        <StyledBody>
            {props.language === "English" ?
                <>
                    <section>
                        <h1>Do you need help to develop your business?</h1>
                        <article className="content">
                            <img src="./presentation.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">With 30 years of experience in partner and other leadership positions at two of the world's largest accounting firms, I offer strategic advisory services primarily for Swedish companies and entrepreneurs.</p>
                                <p>With my broad network I can also provide access to first-class expertise within a range of areas that can add value to your company.</p>
                            </article>
                        </article>
                        <Link to="/contact"><Button>Contact me</Button></Link>
                    </section>
                    <section id="affarsradgivning">
                        <h1>Business Consulting</h1>
                        <article className="content">
                            <img src="./radgivning.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">I offer strategic business advice to help owners and entrepreneurs develop their companies in the following areas:</p>
                                <ul>
                                    <li>Strategic business planning</li>
                                    <li>Mergers and Acquisitions</li>
                                    <li>Business valuation</li>
                                    <li>Change of generation</li>
                                    <li>Sustainable business development</li>
                                    <li>Tax advice</li>
                                </ul>
                            </article>
                        </article>
                    </section>
                    <section id="bolagsstyrning">
                        <h1>Corporate Governance and Leadership</h1>
                        <article className="content">
                            <img src="./bolagsstyrning.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">A key factor for building successful companies is to build a leadership team with complementary strengths at both the operational and strategic level. I can help with:</p>
                                <ul>
                                    <li>Strategic advice for management and board</li>
                                    <li>Advisory Board</li>
                                    <li>Owner directives</li>
                                    <li>Shareholders' Agreements</li>
                                    <li>Incentive programs for key employees</li>
                                    <li>Corporate legal issues</li>
                                </ul>
                            </article>
                        </article>
                    </section>
                    <section id="forelasningar">
                        <h1>Lectures</h1>
                        <article className="content">
                            <img src="./forelasningar.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">My lectures are often case-based, providing concrete and immediate usefulness for the participants.</p>
                                <p>Some examples of my most popular lectures are:</p>
                                <ul>
                                    <li>Developing your company with the right strategy and an effective business model</li>
                                    <li>How to sell your life's work</li>
                                    <li>How to implement active and value-adding board-work</li>
                                    <li>Complex governance and audit issues</li>
                                    <li>Incentives for key employees - possibilities and pitfalls</li>
                                </ul>
                            </article>
                        </article>
                    </section>
                    <section>
                        <h1>About me</h1>
                        <article className="content">
                            <img src="./om-mig.jpg" alt="" />
                            <article className="text-content">
                                <ul className="initial">
                                    <li>I am a passionate leader who always want to challenge, develop and explore.</li>
                                    <li>I have over 20 years of experience in partner and leadership positions at two of the world's largest accounting firms (Grant Thornton and Ernst & Young).</li>
                                    <li>I have over 30 years of experience as an authorized public accountant and have served more than 500 corporations.</li>
                                    <li>Since 2019 I am active as an independent business consultant within corporate governance and business development through my company XP Advisory AB.</li>
                                    <li>I am currently active as board chairman, strategic business advisor and/or angel investor in over 20 corporations.</li>
                                </ul>
                            </article>
                        </article>
                        <article className="investments-intro">
                            <h2>My investments</h2>
                            <p>I also invest in corporations with a potential to create innovative solutions for a more sustainable development of society.</p>
                            <p>These investments are made partly in collaboration with Uppsala Innovation Centre (UIC) and the state-owned venture capital company Saminvest.</p>
                            <p>Below are a few of my portfolio companies:</p>
                        </article>
                        <div className="investment-wrapper">
                            <article className="investments">
                                <article className="investment-article">
                                    <p className="bold">Inossia</p>
                                    <p>Currently developing a patented emollient that repairs cracks in the spine in people with osteoporosis.</p>
                                    <a href="http://www.inossia.com">
                                        <p className="bold">www.inossia.com</p>
                                    </a>
                                </article>
                                <article className="investment-article">
                                    <p className="bold">Altris AB</p>
                                    <p>Developing sustainable batteries to facilitate the transition towards environment-friendly energy technologies.</p>
                                    <a href="http://www.altris.se">
                                        <p className="bold">www.altris.se</p>
                                    </a>
                                </article>
                            </article>
                            <article className="investments">
                                <article className="investment-article">
                                    <p className="bold">Prevet AB</p>
                                    <p>Has created a digital tool for improved and more sustainable medical treatment of animals.</p>
                                    <a href="http://www.prevet.se">
                                        <p className="bold">www.prevet.se</p>
                                    </a>
                                </article>
                                <article className="investment-article">
                                    <p className="bold">Ekobot AB</p>
                                    <p>Has created a robot platform for precision agriculture based on AI.</p>
                                    <a href="https://ekobot.se/">
                                        <p className="bold">www.ekobot.se</p>
                                    </a>
                                </article>
                            </article>
                        </div>
                        <Link to="/contact"><Button>Contact me</Button></Link>
                    </section>
                </>
                :
                <>
                    <section>
                        <h1>Vill du ha hjälp att utveckla ditt företag?</h1>
                        <article className="content">
                            <img src="./presentation.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">Med 30 års erfarenhet som auktoriserad revisor, delägare och chef på två av världens största revisions- och konsultbyråer erbjuder jag strategisk affärsrådgivning för att utveckla ditt företag.</p>
                                <p>Med hjälp av mitt breda nätverk kan jag även förmedla spetskompetens inom en rad områden som kan vara av stort värde för entreprenörer och företag.</p>
                                <p className="bold">Funderar du på nästa steg i ditt företags utveckling?</p>
                                <p>Jag erbjuder:</p>
                                <ul>
                                    <li> Strategisk rådgivning som hjälper dig att ta nästa steg i företagets utveckling.</li>
                                    <li>Konkret problemlösning inom verksamhetsutveckling, företagsaffärer, ledarskap och bolagsstyrning.</li>
                                    <li>Stöd för en hållbar affärsutveckling som möter framtidens krav.</li>
                                </ul>
                            </article>
                        </article>
                        <Link to="/contact"><Button>Kontakta mig</Button></Link>
                    </section>
                    <section id="affarsradgivning">
                        <h1>Affärsrådgivning</h1>
                        <article className="content">
                            <img src="./radgivning.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">Jag erbjuder strategisk affärsrådgivning som hjälper ägare och entreprenörer att utveckla sin verksamhet inom bl. a. följande områden:</p>
                                <ul>
                                    <li>Strategisk affärsplanering</li>
                                    <li>Köp och försäljning av bolag</li>
                                    <li>Företagsvärdering</li>
                                    <li>Generationsskiften</li>
                                    <li>Företagsanalys och verksamhetsutveckling</li>
                                    <li>Skatterådgivning</li>
                                </ul>
                            </article>
                        </article>
                    </section>
                    <section id="bolagsstyrning">
                        <h1>Bolagsstyrning</h1>
                        <article className="content">
                            <img src="./bolagsstyrning.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">För att kunna styra och skala upp ditt bolag med framgång krävs bra verktyg och ett väl fungerande samspel mellan de olika lagdelarna inom teamet. Jag hjälper till med:</p>
                                <ul>
                                    <li>Strategisk rådgivning för styrelser och företagsledning</li>
                                    <li>Advisory Board-uppdrag</li>
                                    <li>Ägardirektiv</li>
                                    <li>Aktieägaravtal</li>
                                    <li>Incitamentslösningar för nyckelpersoner   </li>
                                    <li>Bolagsrättsliga frågor</li>
                                </ul>
                            </article>
                        </article>
                    </section>
                    <section id="forelasningar">
                        <h1>Föreläsningar</h1>
                        <article className="content">
                            <img src="./forelasningar.jpg" alt="" />
                            <article className="text-content">
                                <p className="initial">Jag anlitas ofta som utbildare/föreläsare inom affärsutveckling, styrelseutveckling samt revisionsrådgivning. Ett urval av uppdragsgivare: FAR Akademi, Styrelseakademien och Bonnier akademi m.fl.</p>
                                <p>Mina föreläsningar är oftast case-baserade, vilket ger en konkret och omedelbar användarnytta för deltagarna.</p>
                                <p>Exempel på mina mest populära föreläsningar/utbildningar:</p>
                                <ul>
                                    <li>Utveckla ditt företag med rätt strategi och affärsmodell</li>
                                    <li>Att sälja sitt livsverk - hur gör man?</li>
                                    <li>Att komma igång med ett mer aktivt och värdeskapande styrelsearbete</li>
                                    <li>Komplexa revisions- och rådgivningsfrågor</li>
                                    <li>Att ta in nyckelpersoner som ägare – möjligheter och fallgropar</li>
                                </ul>
                            </article>
                        </article>
                    </section>
                    <section>
                        <h1>Om mig</h1>
                        <article className="content">
                            <img src="./om-mig.jpg" alt="" />
                            <article className="text-content">
                                <ul className="initial">
                                    <li >Jag är en passionerad ledare som alltid vill upptäcka, utmana och utveckla.</li>
                                    <li>Har mer än 20 års erfarenhet som chef och ledare på två av världens största revisions- och konsultbyråer (Grant Thornton och Ernst & Young).</li>
                                    <li>Auktoriserad revisor sedan 30 år och erfarenhet som ansvarig revisor och eller rådgivare till mer än 500 bolag, stiftelser och föreningar.</li>
                                    <li>Är numera verksam som affärsrådgivare inom bolagsstyrning och affärsutveckling (genom det egna bolaget XP Advisory AB).</li>
                                    <li>Är för närvarande engagerad som styrelseordförande i en handfull bolag och som strategisk rådgivare och/eller investerare i ytterligare ca 10-15 bolag.</li>
                                </ul>
                            </article>
                        </article>
                        <article className="investments-intro">
                            <h2>Investeringar</h2>
                            <p>Jag genomför även investeringar i bolag som har stor potential att bidra med innovativa och hållbara lösningar på viktiga samhällsproblem.</p>
                            <p>Investeringarna sker delvis genom ett samarbete med Uppsala Innovation Center (UIC) och med det statliga riskkapitalbolaget Saminvest AB.</p>
                            <p>Några av mina portföljbolag:</p>
                        </article>
                        <div className="investment-wrapper">
                            <article className="investments">
                                <article>
                                    <p className="bold">Inossia AB</p>
                                    <p>Utvecklar en patenterad mjukgörare/metod som reparerar sprickor i ryggraden för personer med benskörhet.</p>
                                    <a href="http://www.inossia.com">
                                        <p className="bold">www.inossia.com</p>
                                    </a>
                                </article>
                                <article>
                                    <p className="bold">Prevet AB</p>
                                    <p>Har utvecklat ett digitalt verktyg för en förbättrad och mer hållbar djursjukvård.</p>
                                    <a href="http://www.prevet.se">
                                        <p className="bold">www.prevet.se</p>
                                    </a>
                                </article>
                            </article>
                            <article className="investments">
                                <article>
                                    <p className="bold">Altris AB</p>
                                    <p>Utvecklar hållbara batterier som gör att vi kan klara omställningen till miljövänlig energiteknik.</p>
                                    <a href="http://www.altris.se">
                                        <p className="bold">www.altris.se</p>
                                    </a>
                                </article>
                                <article>
                                    <p className="bold">Ekobot AB</p>
                                    <p>Har utvecklat en robotplattform för precisionsjordbruk baserat på A.I.</p>
                                    <a href="http://www.inossia.com">
                                        <p className="bold">www.ekobot.se</p>
                                    </a>
                                </article>
                            </article>
                        </div>
                        <Link to="/contact"><Button>Kontakta mig</Button></Link>
                    </section>
                </>
            }
        </StyledBody>
    )
}